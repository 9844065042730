import React, { useContext, useEffect, useState } from "react";
import Step1 from "../StepForm/Step/Step1.js";
import Step2 from "../StepForm/Step/Step2.js";
import Step3 from "../StepForm/Step/Step3.js";
import Step4 from "../StepForm/Step/Step4.js";
import { Link } from "react-router-dom";
import "../create-bill/all-tab/alltabs.css";
import view from "./image/view.png";
import restore from "./image/restore.png";
import arrowimg from "./image/arrow-circle-right.png";
import { Context } from "../../../utils/context.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import StepForm from "../../StepForm/StepForm";

const StepForm = (props) => {
  const { getData, deleteData } = useContext(Context);
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(0);

  const getPropertyData = async () => {
    const response = await getData(
      "/createbill/propertyinfo/getallpropertydetails"
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getPropertyData();
  }, [reload]);

  const [formData, setFormData] = useState({});

  const [billinHistory, setBillinHistory] = useState(false);

  // const [isActive, setIsActive] = useState(true);
  const handleClick = (e) => {
    setCurrentStep(Number(e.target.value));
    // setIsActive(!isActive);
    // if (billinHistory) {
    //   setBillinHistory(!billinHistory);
    // }
  };

  const [currentStep, setCurrentStep] = useState(1);

  const HandleChangesss = async (e) => {
    // if (billinHistory) {
    //   setBillinHistory(!billinHistory);
    // }
    setCurrentStep(Number(e.target.value));
  };

  const handleChange = (eventOrStep, value) => {
    if (typeof eventOrStep === "string") {
      if (eventOrStep === "next") {
        setCurrentStep((prevStep) => prevStep + 1);
      } else if (eventOrStep === "prev") {
        setCurrentStep((prevStep) => prevStep - 1);
      }
    } else {
      const { name, value } = eventOrStep.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1
            reload={props.isActive3}
            formData={formData}
            handleChange={handleChange}
            setFormData={setFormData}
          />
        );
      case 2:
        return (
          <Step2
            formData={formData}
            handleChange={handleChange}
            setFormData={setFormData}
          />
        );
      case 3:
        return (
          <Step3
            formData={formData}
            handleChange={handleChange}
            setFormData={setFormData}
          />
        );
      case 4:
        return (
          <Step4
            formData={formData}
            handleChange={handleChange}
            setFormData={setFormData}
          />
        );
      default:
        return null;
    }
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/createbill/propertyinfo/deletedata/${recordToDeleteId}`
      );
      setRecordToDeleteId(null);
      if (response.success) {
        setRecordToDeleteName(null);
        setReload(response);
      }
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };
  return (
    <>
      <section className="create-bill">
        <div className="container">
          <div className="row mt-4">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 p-0">
              <div
                className="nav nav-pills yellow-background me-3"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <div className="tabsverticalscroll mt-3">
                  <button
                    onClick={handleClick}
                    className={
                      currentStep == 1
                        ? "nav-link active mb-3"
                        : "nav-link  mb-3"
                    }
                    value={1}
                    id="v-pills-Property-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-Property"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-Property"
                    aria-selected="true"
                  >
                    Property Info tab{" "}
                    <img src={arrowimg} className="arrow-img" />
                  </button>

                  <button
                    className={
                      currentStep == 2
                        ? "nav-link active mb-3"
                        : "nav-link  mb-3"
                    }
                    value={2}
                    onClick={HandleChangesss}
                    id="v-pills-Details-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-Details"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-Details"
                    aria-selected="false"
                  >
                    Construction Details tab{" "}
                    <img src={arrowimg} className="arrow-img" />
                  </button>

                  <button
                    className={
                      currentStep == 3
                        ? "nav-link active mb-3"
                        : "nav-link  mb-3"
                    }
                    value={3}
                    onClick={HandleChangesss}
                    id="v-pills-Flat-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-Flat"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-Flat"
                    aria-selected="false"
                  >
                    Flat Details tab
                    <img src={arrowimg} className="arrow-img" />
                  </button>

                  <button
                    className={
                      currentStep == 4
                        ? "nav-link active mb-3"
                        : "nav-link  mb-3"
                    }
                    value={4}
                    onClick={HandleChangesss}
                    id="v-pills-Room-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-Room"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-Room"
                    aria-selected="false"
                  >
                    Room Details tab
                    <img src={arrowimg} className="arrow-img" />
                  </button>
                  <button
                    // className={
                    //   isActive
                    //     ? "show-bill-tab nav-link"
                    //     : "nav-link hide-bill-tab"
                    // }
                    className="show-bill-tab nav-link"
                    id="v-pills-Bill-tab"
                    type="button"
                    role="tab"
                    onClick={() => {
                      setBillinHistory(!billinHistory);
                    }}
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Bill History
                    <img src={arrowimg} className="arrow-img" />
                  </button>
                  <div
                    className={
                      billinHistory ? "collapse show mt-3" : "collapse mt-3"
                    }
                    id="collapseExample"
                  >
                    <div className="card history">
                      <div className="main p-3">
                        {data?.map((item, index) => (
                          <div className="row">
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-5 col-5">
                              <p className="kumar">
                                {item?.propertyownerdetails?.[0]?.first_name}
                              </p>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-5 col-5 ">
                              {item?.data_entry_head_accept_rejects ? (
                                item?.data_entry_head_accept_rejects.length !==
                                0 ? (
                                  item?.data_entry_head_accept_rejects[0]
                                    .accept_reject_id !== null ? (
                                    item?.data_entry_head_accept_rejects[0]
                                      .accept_reject_id == 1 ? (
                                      <p className="accept">
                                        {
                                          item
                                            ?.data_entry_head_accept_rejects[0]
                                            ?.accept_reject?.name
                                        }
                                      </p>
                                    ) : (
                                      <p className="reject">
                                        {
                                          item
                                            ?.data_entry_head_accept_rejects[0]
                                            ?.accept_reject?.name
                                        }
                                      </p>
                                    )
                                  ) : (
                                    <>
                                      <p className="none">New</p>
                                    </>
                                  )
                                ) : (
                                  <>
                                    <p className="none">New</p>
                                  </>
                                )
                              ) : (
                                <>
                                  <p className="none">New</p>
                                </>
                              )}
                            </div>
                            <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1">
                              <Link to={`/historyview/${item.id}`}>
                                <img
                                  src={view}
                                  className="view-img"
                                  type="button"
                                />
                              </Link>
                            </div>
                            <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1">
                              <Link to={`/history/${item.id}`}>
                                <img
                                  src={restore}
                                  className="restore-img"
                                  type="button"
                                />
                              </Link>
                            </div>
                            <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1">
                              <Link
                                onClick={() => {
                                  showDeleteRecord(
                                    item?.id,
                                    item?.propertyownerdetails[0]?.first_name
                                  );
                                }}
                                // to={`/history/${item.id}`
                              >
                                <FontAwesomeIcon
                                  icon="fa-solid fa-trash-can"
                                  style={{ color: "#000000" }}
                                  className="restore-img mt-1"
                                />
                              </Link>
                            </div>
                            <hr></hr>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12">
              <div className=" ">
                <div>
                  {renderStep()}
                  {currentStep > 1 && (
                    <button
                      onClick={() => handleChange("prev")}
                      className="back-button-class"
                    ></button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{
            display: showDeleteModal ? "block" : "none",
          }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div
              className="modal-content"
              style={{ backgroundColor: "#fe7600" }}
            >
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button
                    className="btn btn-yes me-2"
                    onClick={handleDeleteRecord}
                  >
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepForm;
